import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../layouts/"
import SEO from "../../components/seo/"
import Crumbs from "../../components/breadcrumb/"
import BackNav from "../../components/back-nav/"

import Split from "../../components/split/"
import TextBlock from "../../components/text-block/"
import ImageBlock from "../../components/image-block/"

import { psColour, wlColour, siteColour } from "../../data/data.colours"

const AboutPage = ({data, pageContext, location}) => {
	
	const image = useStaticQuery(graphql`
		query {
			aboutBg: file(relativePath: { eq: "backgrounds/tree-01.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 2000) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		}
	`)
	
	return (
		<>
			<SEO title="About"
				path={ location.pathname }/>
			<Layout>
				<Crumbs
					full
					pageContext={pageContext}
					location={location}/>
				<BackNav
					hex={wlColour.hex}
					link={`/`}/>
				<Split>
					<ImageBlock 
						image={ data.aboutBg.childImageSharp.fluid }
						position={`45% 100%`}/>
					<TextBlock 
						title={`About us`}
						titleTag={ `h1` }
						text={`
							<p>Painesend Farm LLP and W Lamb Ltd are operated out of the same office at Painesend Farm in Tring. Peter Vallis and Ross Alberto are the two main principals. The businesses own a number of residential properties which are all let and managed in-house. The farm partnership owns and manages farmland whilst W Lamb Ltd constructs and manages the subsequent sale or letting of both residential and commercial properties.</p>
							<h2>Joint Ventures</h2>
							<p>We have a successful track record of working in joint venture with third parties, vendors, financiers; and other property developers. We understand that for a JV to be successful it must recognise the needs of both parties and accordingly, we employ a flexible approach to such structures.</p>
							<h2>Land for purchase</h2>
							<p>We are always looking for new development opportunities and are very receptive to purchasing plots of land with or without planning.</p>
						`}
						bgColour={wlColour.slug}/>

				</Split>
			</Layout>
		</>
	)
}

export default AboutPage